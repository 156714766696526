@import '../../styles/_media';

.ContactUs_container {
  .Contact_form_wrapper {
    display: flex;
    background-color: #283047;
    gap: 2rem;
    @include mobile {
      flex-direction: column;
      gap: unset;
    }
    .Left_side {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffff;
      background-image: url("../../assets/contactUs.png");
      gap: 1rem;
      .Text_wrapper {
        max-width: 424px;

        @include mobile {
          padding: .5rem;
        }
       
        .Text {
          font-size: 45px;
          line-height: 55px;
          margin-bottom: 2rem;

          @include mobile {
            font-size: 36px;
            line-height: 44px;
          }

          .Sub_text {
            color: #de0000;
          }
        }

        .Description {
          font-weight: 400;
          font-family: "Clash Grotesk";
          line-height: 24px;
        }
      }
    }
    .Right_side {
      flex: 1;
      color: #ffff;
      margin: 5rem 0;

      @include mobile {
        padding: .5rem;
        margin: 2rem 0;
      }
      .content {
        display: grid;
        grid-template-columns: 65% auto;
        gap: 5rem;

        @include mobile {
          grid-template-columns: unset;
        }
      }

      form {
        width: 100%;

        label {
          display: block;
          width: 100%;
          margin-bottom: 8px;
          font-weight: bold;
          margin-bottom: 1rem;
        }

        input,
        textarea {
          color: #fff;
          width: 100%;
          padding: 16px 0 12px;
          margin-bottom: 16px;
          border: none;
          font-size: 1rem;
          background: transparent;
          border-bottom: 1px solid #ccc;
          box-sizing: border-box;

          &:focus {
            outline: none;
            background-color: transparent;
          }
        }

        .field-group {
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 2rem;

          @include mobile {
            gap: 1rem;
          }
        }

        .submit {
          padding: 1rem 3.145rem;
          cursor: pointer;
          font-size: 1rem;
          font-weight: bold;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-left: auto;
          color: #ffff;
          border: 1px solid #de0000;
          background: #de0000;
        }

        .submit-disable {
          padding: 1rem 3.145rem;
          cursor: pointer;
          font-size: 1rem;
          font-weight: bold;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-left: auto;
          background: #de0000;
          color: #ffff;
          border: 1px solid #de0000;
          opacity: .3;
        }
      }
    }
  }

  .Address_container {
    display: flex;
    gap: 1.5rem;
    max-width: 1200px;
    justify-content: center;
    margin: 2.5rem auto;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid;

    @include mobile {
      flex-direction: column;
      gap: unset;
      margin: unset;
      
    }
    .Location_wrapper {
      min-width: 588px;
      min-height: 282px;
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fcfbf7;
      @include mobile {
        gap: 1rem;
        min-width: unset
      }
      .Heading {
        min-width: unset;
      }

      .Text {
        width: 468px;
        text-align: center;
        line-height: 20px;

        @include mobile {
          width: unset
        }
      }
    }

    .Email_wrapper {
      min-width: 282px;
      min-height: 282px;
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #fcfbf7;

      .Heading {
        font-size: 22px;
        font-weight: 500;
      }
    }
    .Phone_wrapper {
      min-width: 282px;
      min-height: 282px;
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #fcfbf7;

      .Heading {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }

  .FollowUs_container {
    display: flex;
    max-width: 1200px;
    justify-content: space-between;
    margin: 0 auto 2.5rem;

    @include mobile {
      margin:1.5rem 0;
    }
    .Social_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .Image_wrapper {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        width: 22px;
        height: 22px;
        cursor: pointer;
        .Instagram_img {
          width: 38px;
          height: 38px;
        }

        .WhatsApp_container_mob {
          display: none;

          @include mobile {
            display: block;
          }
          svg {
            height: 36px;
            width: 36px;
          }
        }
      }
    }

    .WhatsApp_container {
      @include mobile {
       display: none;
      }
      .WhatsApp_icon {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: black;
        text-transform: capitalize;
        gap: 2px;

        svg {
          height: 36px;
          width: 36px;
        }
      }
    }
  }
}
