@import '../../styles/_media';

.Category_container {
    .Banner_image {
        position: relative;
        img {
            width: 100%;
            min-height: 400px;
            object-fit: cover;
        }

        .Breadcrumbs_container {
            position: absolute;
            top: 20px;
            font-size: 14px;
            padding-left: 5.5rem;
            text-transform: capitalize;

            @include mobile {
                padding-left: 1.4rem;
            }
            .Breadcrumbs_wrapper {
                color: #ffff;
                display: flex;
                gap: 5PX;
                a {
                    color: #ffff;
                    text-decoration: none;
                }

                .Root_page, span {
                    color: darkgray;
                }
            }
        }

        .BannerText_wrapper {
            position: absolute;
            top: 30%;
            left: 38%;
            text-align: center;
            text-transform: capitalize;

            @include mobile {
                left: unset;
            }

            .Text {
                font-size: 40px;
                width: 480px;
                color: white;
                font-weight: 500;
                line-height: 54px;

                @include mobile {
                    width: unset;
                    font-size: 32px;
                    line-height: 50px;
                }
            }

            button {
                margin-top: 1.5rem;
                padding: 1rem 2rem;
                background: transparent;
                color: white;
                font-weight: 700;
                border: 2px solid;
                cursor: pointer;

                &:hover {
                    color: #ffff;
                    background: #DE0000;
                    border: 2px solid #DE0000;
                }
            }
        }
        
    }

    .Details_wrapper_text {
        max-width: 1220px;
        text-align: center;
        margin: 0 auto 3.5rem;
        line-height: 24px;
        font-family: Clash Grotesk;
        padding: 2.5rem 0;
        border-bottom: 1px solid #CACACA;

        @include mobile {
            margin: 0 auto 1.5rem;
            padding: 1rem .5rem;
        }
    }

    .Discover_future_container {
        max-width: 1200px;
        margin: 4rem auto;
        display: flex;
        gap: 1.5rem;
        align-items: center;

        @include mobile {
            flex-direction: column;
            margin: 2rem auto;
            padding: 1rem;
        }

        .Left_side {
            img {
                @include mobile {
                    max-width: 100%;
                } 
            }
        }
        .Right_side {

            .Title {
                font-size: 36px;
                max-width: 478px;
                margin-bottom: 2rem;

                .Sub_text {
                    color: #DE0000;
                }
            }

            .Long_text {
                line-height: 24px;
            }
        }
    }
}