@import '../../styles/_media';

.SafetyAd_container {
    display: grid;
    justify-content: center;
    background: #FCFBF7;
    margin-top: 3rem;
    width: 100%;

    @include mobile {
        padding: 1rem;
        margin-top: 0rem;
    }
    .Heading {
        font-size: 36px;
        width: 538px;
        margin: 3rem 0 1.5rem;
        line-height: 46px;

        @include mobile {
            font-size: 28px;
            padding: 0rem 0rem 1rem;
            line-height: 36px;
            width: unset;
            margin: unset;
            margin-top: 0rem;
        }
    }

    .Details_wrapper {
        display: flex;
        gap: 1.5rem;
        padding-bottom: 4rem;
        @include mobile {
            flex-direction: column;
            padding-bottom: 1rem;
        }
        .Left_side_wrapper {
            position: relative;

            .Main_img {
                img {
                    --mask: radial-gradient(75px at 100% 0,#0000 98%,#000);
                    -webkit-mask: var(--mask);
                            mask: var(--mask);
                    
                    @include mobile {
                        max-width: 100%;
                        -webkit-mask: unset;
                    }
                }
            }

            .Ads_img_wrapper {
                position: absolute;
                top: -82px;
                right: -82px;

                .Ads_img_inner_wrapper {
                    position: relative;

                    @include mobile {
                        display: none;
                    }

                    .Ads_img {
                        position: absolute;
                        left: 30px;
                        bottom: 0;
                        right: 0;
                        top: 30px;
                    }

                    .Circle_img {
                        animation: imageRotate 8s linear infinite;
                        animation-direction: reverse;
                    }

                    @keyframes imageRotate {
                        0%{
                           transform: rotate(0deg);
                          }
                       100%{
                           transform: rotate(360deg);
                          }
                    }
                }
            }
        }

        .Right_side_wrapper {
            width: 486px;
            display: flex;
            flex-direction: column;
            justify-content: end;
            gap: 2rem;
            margin-bottom: 4px;
            @include mobile {
                width: unset;
            }
            .Long_text {
                line-height: 24px;
                font-weight: 400;
            }

            button {
                padding: 14px 28px 14px 28px;
                font-weight: 600;
                border: 1px solid #000000;
                width: fit-content;
                cursor: pointer;
                &:hover {
                    color: #ffff;
                    background: #DE0000;
                    border: 1px solid #DE0000;
                }
            
            }
        }
    }
}