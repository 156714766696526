@import '../../styles/_media';

.Footer_container {
    background: #0A0E00;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    gap: 8rem;
    padding: 4.5rem 0;

    @include mobile {
        flex-direction: column;
        padding: 1rem;
        gap:2rem;
    }

    .Left_side {
        .Heading {
            width: 588px;
            font-size: 45px;
            line-height: 56px;

            @include mobile {
               width: unset;
               font-size: 24px;
               line-height: 34px;
            }
            .Inner_text {
                color: #DE0000;
            }
        }


        .Social_wrapper {
            margin-top: 6rem;
            font-size: 22px;
            font-weight: 500;

            @include mobile {
                margin-top: 2rem;
            }
            .Image_wrapper {
                padding-top: 1rem;
                display: flex;
                gap: 1.5rem;
                cursor: pointer;
            }
        }
    }

    .Right_side {
        .Reach_text {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 1.5rem;
            line-height: 56px;

            @include mobile {
                margin-bottom: 1rem;
            }
        }

        .React_us_wrapper {
            padding-left: 14px;
            .Ul_list {
                font-weight: 500;
                padding-bottom: 1.4rem;
            }
            .Corporate_address {
                width: 462px;
                color: #9A9A9A;
                padding-bottom: 2.5rem;
                line-height: 20px;

                @include mobile {
                    width: unset;
                }
                
            }

            .Branch_address {
                color: #9A9A9A;
                padding-bottom: 2.5rem;
            }
        }

        .Connect_us_wrapper {
            border-top: 1px solid #ffff;
            .Connect_text {
                font-size: 22px;
                font-weight: 500;
                margin: 1.5rem 0;
                line-height: 56px;
            }
            .Connect_info {
                display: flex;
                align-items: center;
                gap: 1rem;
                padding-bottom: 1.5rem;
                .Mail, .Phone_number {
                    color: #9A9A9A;
                }

                .Line {
                    width: 1px;
                    height: 14px;
                    background: #9A9A9A;
                }
            }

            .WhatsApp_container {
                cursor: pointer;
                .WhatsApp_icon {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    color: #9A9A9A;
                    text-decoration: none;
                    text-transform: capitalize;
                    svg {
                        width: 36px;
                        height: 36px;
                    }
                }
            }
        }
    }
}