@import "../../../styles/_media";

.Mobile-header-container {
  display: none;
  @include mobile {
    display: block;
  }

  background-color: #ffffff;
  border-bottom: 1px solid #f6f6f6;
  position: sticky;
  top: 0;
  z-index: 10000;
  padding: 1rem;
  .Header-Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .Left_wrapper {
      img {
        max-width: 104px;
      }
    }
  }

  .sidenav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 0%;
    z-index: 9999;
    overflow-y: scroll;
    // padding: 1.875rem 0;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    // align-items: center;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.3s;

    .Close-icon {
      padding: 1rem;
    }

    .Logo-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;

        .Image {
            max-width: 150px;
          }
    }
   

    .Link-wrapper {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      gap: 1.5rem;
      margin-top: 2rem;
      padding: 1rem;

      a {
        color: black;
        text-decoration: none;
      }
    }
  }
}
