@import "./styles/_media";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Clash Grotesk';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
// @font-face {
//   font-family: Clash Grotesk;
//   src: url("./utils/fonts/ClashGrotesk_Complete/Fonts/OTF/ClashGrotesk-Regular.otf") format("opentype");
// }


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

