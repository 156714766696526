@import '../../styles/_media';

.Review_container {
    max-width: 1200px;
    margin: 5.5rem auto 3rem;

    @include mobile {
        max-width: unset;
        margin: unset;
        padding: 1rem;
    }

    .Top_wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid;

        @include mobile {
            flex-direction: column;
        }
        .Heading_wrapper {
            .Small_heading {
                display: flex;
                align-items: center;
                color: #DE0000;
                margin-bottom: .8rem;
            }

            .Info {
                width: 480px;
                font-size: 36px;
                line-height: 45px;

                @include mobile {
                    width: unset;
                }
            }
        }

        .Detail_wrapper {
            p {
                line-height: 24px;
            }
        }
    }

    .Review_wrapper {
        display: flex;
        gap: 1.5rem;
        margin-top: 2rem;

        @include mobile {
            gap: 1rem
        }

        .Info_wrapper {
            width: 588px;
            background: #FCFBF7;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3rem 0;

            @include mobile {
                width: unset;
                padding: 1rem;
            }

            .Long_text {
                width: 492px;
                font-size: 22px;

                @include mobile {
                    width: unset;
                    font-size: 18px;
                }
            }

            .Compony_name {
                .material-symbols-outlined {
                    font-size: 16px;
                }
                display: flex;
                align-items: center;
                gap: 4px;
                padding-top: 2.5rem;
            }
        }
    }
}