@import '../../styles/_media';

.Trust_count_container {
    display: inline-flex;
    margin: 1.5rem auto;
    width: 100%;
    justify-content: space-evenly;
    padding: 0px 8rem;
    align-items: center;
    @include mobile {
        padding: unset;
    }
    .Trust_wrapper {
        p {
            text-align: center;
        }

        .Count {
            font-size: 45px;
            font-weight: 600;

            @include mobile {
                font-size: 24px;
            }
        }

        .Text {
            padding-top: 2px;
            @include mobile {
                font-size: 14px;
            }
        }
    }

    .Line {
        width: 1px;
        height: 60px;
        background: #CACACA4D
    }
}