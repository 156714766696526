@import '../../styles/_media';

.Header_container {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.8rem 6rem;
  background-color: #ffffff;
  border-bottom: 1px solid #f6f6f6;
  position: sticky;
  top: 0;
  z-index: 10000;

  @include mobile {
    display: none;
  }

  .Header_wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .Right_side {
      display: flex;
      gap: 2rem;
      .Construction_wrapper {
        .Alert_text {
          background-color: #de0000;
          color: #ffff;
          font-weight: 600;
          font-size: 12px;
          padding: 4px 4px;
          border-radius: 6px;
          margin-right: 6px;
          animation: AlertColorChange 5s ease-in-out infinite;
        }

        @keyframes AlertColorChange {
          0% {
            background-color: #de0000;
          }
          100% {
            background-color: #5b2121;
          }
        }
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        color: #000;
        text-decoration: none;

        &:hover {
            color: #DE0000;
        }
      }
      .Industrial_wrapper, .Industrial_wrapper_link {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        color: #000;
        text-decoration: none;
        &:hover {
            color: #DE0000;
        }
      }
      .material-symbols-outlined {
        // font-size: 30px;
      }

      button {
        padding: 14px 28px 14px 28px;
        cursor: pointer;
        font-weight: 600;

        &:hover {
          color: #ffff;
          background: #DE0000;
          border: 2px solid #DE0000;
        }
      }
    }
    .Left_wrapper {
      width: 144px;
      height: 37px;

      img {
        max-width: 100%;
      }
    }
  }
}
