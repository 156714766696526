.ProductList_container {
  padding: 2rem 6rem;
  .Breadcrumbs_container {
    text-transform: capitalize;
    margin-bottom: 2rem;
    font-size: 14px;
    .Breadcrumbs_wrapper {
      color: black;
      display: flex;
      gap: 5px;
      a {
        color: black;
        text-decoration: none;
      }

      .Root_page, span {
          color: darkgray;
      }
    }
  }

  .ProductList_container_wrapper {
    display: flex;
    gap: 4rem;
    align-items: self-start;
    .Left_side {
      width: 282px;
      padding: 2.7rem 1.5rem;
      background-color: #fcfbf7;
      height: fit-content;
      .Heading {
        font-size: 22px;
        font-weight: 500;
        padding-bottom: 2.7rem;
      }

      .List_wrapper {
        ul {
          display: flex;
          flex-direction: column;
          gap: 24px;
          list-style: none;
          font-family: "Clash Grotesk";
        }
      }
    }

    .Right_side {
      max-width: 900px;

      .Details_wrapper {
        .Heading_wrapper {
          display: flex;
          gap: 10px;
          align-items: center;
          .Heading {
            font-size: 36px;
            font-weight: 500;
          }

          .Count {
            color: #de0000;
          }
        }

        .Info_text {
          line-height: 24px;
          padding: 1rem 0 2rem;
          border-bottom: 1px solid;
          margin-bottom: 2rem;
        }
      }

      .Product_list_Container {
        .Product_list_wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 1.3rem;

          .Slider_img_wrapper {
            .Img_wrapper {
              background: #f7f7f7;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 282px;
              min-width: 282px;
              img {
                max-width: 202px;
                max-height: 172px;
              }
            }

            .Details_wrapper {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              margin: 1rem 0;

              .Brand_name {
                color: #283047;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}
