@import '../../styles/_media';


.AboutUs_container {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin-top: 4rem;

    @include mobile {
        flex-direction: column;
        margin-top: 2rem;
        gap: 1rem;
    } 
    .Left_side_container {
        @include mobile {
            img {
                width: 100%;
            }
        } 
    }
    .Right_side_container {
        width: 564px;
        margin-top: 3rem;

        @include mobile {
            width: unset;
            margin: 0 1rem;
        }
        .Heading {
            display: flex;
            align-items: center;
            color: #DE0000;
            padding-bottom: 20px;
        }

        .Sub_heading {
            font-size: 36px;
            width: 439px;
            padding-bottom: 44px;

            @include mobile {
                width: unset;
                font-size: 30px;
                padding-bottom: 24px;
            }
        }

        .Long_text {
            padding-bottom: 24px;
            font-weight: 400;
            line-height: 24px;
        }

        .Sub_long_text {
            line-height: 24px;
            padding-bottom: 1.7rem;
            border-bottom: 1px solid;
        }

        .Social_wrapper {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 1.2rem 0;

            .Image_wrapper {
                display: flex;
                align-items: center;
                gap: 1.5rem;
                width: 22px;
                height: 22px;

                .Instagram_logo {
                    height: 36px;
                }
            }
        }
    }
}