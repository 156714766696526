@import '../../styles/_media';

.Services_container {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    margin-top: 5rem;

    @include mobile {
        margin: 2rem 1rem 0;
    }

    .Text_wrapper {
        .Heading {
            display: flex;
            gap: 2px;
            align-items: center;
            color: #DE0000;
        }

        .Sub_text {
            width: 300px;
            line-height: 48px;
            font-size: 36px;
            margin-top: 12px;

            @include mobile {
                font-size: 24px;
                line-height: unset;
            }
        }
    }
    .Services_wrapper {
        display: flex;
        gap: 1.5rem;
        margin-top: 1.2rem;

        @include mobile {
            flex-direction: column;
        }
        .Image_container {
            width: 384px;

            @include mobile {
                width: unset;
            }

            img {
                max-width: 100%;
            }

            .Heading_one {
                font-size: 24px;
                font-weight: 500;
                padding-top: 28px;

                @include mobile {
                    padding-top: 16px;
                }
            }

            .Long_text {
                font-family: "Clash Grotesk";
                padding-top: 24px;

                @include mobile {
                    padding-top: 16px;
                }
            }
        }

        .Two {
            position: relative;
            bottom: 7.2rem;

            @include mobile {
                position: unset;
                bottom: unset;
            }
        }
    }
}