@import '../../styles/_media';

.Brand_carousel_wrapper {
  margin: 1.5rem auto 0;
  width: 100%;

  @include mobile {
    margin: 1rem auto;
  }

  .img2 {
    background: #00ca62;
    height: 30em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .marquee {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
  }

  .marquee-content {
    width: 100%;
    display: flex;
    line-height: 30px;
    animation: marquee 4s linear infinite;
  }

  .list-inline {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 6rem;
    list-style: none;
    padding: 2em;
    margin: 0;

    @include mobile {
      padding: 0;
    }

    
    img {
        max-width: 150px;
    }
  }
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-66.6%);
    }
  }
}
