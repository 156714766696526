.Mega_menu_container {
    .Inner_mega_menu {
        display: flex;
        justify-content: end;
        margin-right: 6rem;
        position: fixed;
        right: 0;
        z-index: 2000;
        background: #ffff;
        border-top: 1px solid red;
        .Menu_wrapper {
            display: grid;
            grid-template-columns: auto auto auto;
            width: 1200px;
            justify-content: space-between;
            row-gap: 2rem;
            padding: 4rem 5rem;
    
            .Content_wrapper {
                display: flex;
                align-items: center;
                gap: 1.5rem;
                text-decoration: none;
                color: black;

                .Heading{
                    cursor: pointer;
                    &:hover {
                        color: #DE0000;
                    }
                }
            }
        }
    }
    
}