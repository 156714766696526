@import '../../styles/_media';

.BannerSlider_container {

    .BannerSlider_wrapper {
        img {
             width: 100vw;
            @include mobile {
                width: unset;
            }
        }
        #react-slick {
            .slick-prev {
                z-index: 2000 !important;
                left: 50px !important;
            }
    
            .slick-next {
                right: 50px !important;
                top: 48% !important;
            }
            .slick-prev, .slick-next {
                &::before {
                    content: unset;
                    width: unset;
                    height: unset;
                }

                @include mobile {
                    display: none;
                }
            }
        
            .slick-prev {
                right: 70px;
                left: unset;
                rotate: 180deg;
            }
            .slick-dots {
                bottom: 30px;
            }
            .slick-dots li.slick-active button:before {
                opacity: .75;
                color: white !important;
            }
    
            .slick-dots li button:before {
                color: white !important;
            }

            .Image_wrapper {
                img {
                    max-height: 650px;
                    object-fit: cover;

                    @include mobile {
                        height: 100vh;
                    }
                }
            }
        }
    }

    .Banner_text_wrapper {
        position: absolute;
        top: 32%;
        left: 34%;
        width: 558px;
        color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        align-items: center;

        @include mobile {
            width: 360px;
            left: 2%;
            top: 25%;
        }

        .Main_text {
            font-size: 45px;
            line-height: 55px;
            text-align: center;
            font-weight: 500;

            @include mobile {
                font-size: 36px;
                line-height: 40px;
            }
        }

        button {
            padding: 14px 28px;
            background: transparent;
            color: #ffff;
            border: 1px solid #ffff;
            font-weight: 600;
            width: fit-content;
            cursor: pointer;

            &:hover {
                color: #ffff;
                background: #DE0000;
                border: 1px solid #DE0000;
            }
        }
    }
    
}