@import '../../styles/_media';

.Sub_category_container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1220px;
    margin: 0 auto;
    gap: 1.5rem;

    @include mobile {
        justify-content: center;
    }

    .Heading_wrapper {
        min-width: 282px;
        max-height: 261px;
        display: flex;
        align-items: center;
        .Heading {
            width: 176px;
            font-size: 36px;

            @include mobile {
                font-size: 30px;
            }
        }
    }

    .Image_wrapper {
        position: relative;

        .Title {
            position: absolute;
            bottom: 20px;
            left: 20px;
            color: #ffff;
            font-weight: 500;
        }
    }
}