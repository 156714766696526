@import '../../styles/_media';

.Product_slider_container {
    max-width: 1200px;
    margin: 3rem auto;
    position: relative;

    @include mobile {
        margin: 1rem 0;
        padding: 1rem;
    }

    .Heading {
        padding-bottom: 1.5rem;
        line-height: 44px;

        @include mobile {
            font-size: 24px;
        }
    }
    .slick-slide {
        padding: 0 1rem 0 0;
    }

    .slick-prev, .slick-next {

        &::before {
            content: unset;
        }
    }

    .slick-prev {
        right: 70px;
        left: unset;
        rotate: 180deg;
    }

    .slick-next {
        right: 37px;
    }
    
    .slick-arrow {
        top: -55px;
    }

    .Slider_img_wrapper {
        .Img_wrapper {
            background: #F7F7F7;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 282px;
        }

        .Details_wrapper {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin: 1rem 0;

            @include mobile {
                margin: unset;
            }

            .Brand_name {
                color: #283047;
                font-weight: 500;
            }
        }
    }
}